div.GameLobby{
	/* background-color: white; */
	background-image: repeating-linear-gradient(-45deg, #000, #444c43 1px, transparent 1px, transparent 6px);
	background-size: 4px 4px;
	padding-top:2vw;
	width:100vw;
	text-align: center;
	height:100vh;
}


div.GameLobby h2{
letter-spacing: 1vw;
font-family: 'Black Ops One', cursive;

}
div.GameLobby ul{
	margin-bottom: 2vw;
	margin-left:0;
	padding: 0 1vw;
}

div.GameLobby li{
	text-align: left;
	color: #000;
  letter-spacing: .5vw;
	list-style: none;
	margin: 1vw;
	box-shadow: 0 5px 7px -1px rgb(51 51 51 / 23%);
	background: #fff;
	/* border: 1px dashed #bba174; */
	border-radius: 1vw;
	padding:1vw;
	font-size: 2vw;
}

button.leave-game{
	position: absolute;
	/* background-color: #fff;
	background-image: linear-gradient(180deg, rgba(255, 255, 255, 0.741) 0%, rgba(255, 255, 255, 0.49) 100%); */
    font-size: 2.5vw;
    right: 2vw;
    top: 1vw;
}
button.start-game{
	position: absolute;
	background-color: greenyellow;
	background-image: linear-gradient(180deg, rgb(157, 178, 121) 0%, darkolivegreen 100%);

    font-size: 2.5vw;
    right: 13.5vw;
    top: 1vw;
}

/* from https://codepen.io/alexzaworski/pen/LEWRxm*/
/* .button {
  cursor: pointer;
  margin-left: 5px;
  margin-bottom: 15px;
  text-shadow: 0 -2px 0 #736246, 0 1px 1px #dae2c8;
  box-sizing: border-box;
  font-size: 2em;
  font-family: Helvetica, Arial, Sans-Serif;
  text-decoration: none;
  font-weight: bold;
  color: #ddca9c;
  height: 65px;
  line-height: 65px;
  padding: 0 32.5px;
  display: inline-block;
  width: auto;
  background: linear-gradient(to bottom, #eae09c 0%, #ddca9c 26%, #736246 100%);
  border-radius: 5px;
  border-top: 1px solid #dae2c8;
  border-bottom: 1px solid #dae2c8;
  top: 0;
  transition: all 0.06s ease-out;
  position: relative;
}
.button:visited {
  color: #ddca9c;
}

.button:hover {
  background: linear-gradient(to bottom, #ede6b6 0%, #dbcfb2 26%, #746a5b 100%);
}

.button:active {
  top: 6px;
  text-shadow: 0 -2px 0 #736246, 0 1px 1px #dae2c8, 0 0 4px white;
  color: white;
}
.button:active:before {
  top: 0;
  box-shadow: 0 3px 3px rgba(0, 0, 0, 0.7), 0 3px 9px rgba(0, 0, 0, 0.2);
}

.button:before {
  display: inline-block;
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  z-index: -1;
  top: 6px;
  border-radius: 5px;
  height: 65px;
  background: linear-gradient(to top, #1e5033 0%, #378357 6px);
  transition: all 0.078s ease-out;
  box-shadow: 0 1px 0 2px rgba(0, 0, 0, 0.3), 0 5px 2.4px rgba(0, 0, 0, 0.5), 0 10.8px 9px rgba(0, 0, 0, 0.2);
} */

