div.Games{
	padding-top:2vw;
	background-image: repeating-linear-gradient(-45deg, #000, #444c43 1px, transparent 1px, transparent 6px);
	background-size: 4px 4px;
	width:100vw;
	text-align: center;
	height:100vh;
}
div.Games h1{
	font-size:4vw;
	font-family: 'GoodTimesRg-Regular';

}
div.Games ul{
	margin-bottom: 2vw;
	margin-left:0;
	padding: 0 1vw;
}

div.Games li{
	text-align: left;
	color: #000;
  letter-spacing: .5vw;
	list-style: none;
	margin: 1vw;
	box-shadow: 0 5px 7px -1px rgb(51 51 51 / 23%);
	background: #fff;
	/* border: 1px dashed #bba174; */
	border-radius: 1vw;
	padding:1vw;
	font-size: 2vw;

}
div.Games article.section button {
  font-size: 4vw;
	margin:1vw auto;
  /* text-shadow: 0 0 2vw rgb(29, 30, 34);
  box-shadow: 0 0 6vw rgb(29, 30, 34); */
	width:90%;
  /* color: #ddca9c; */
  font-family: 'Black Ops One', cursive;
}
button.join-game{
	position: absolute;
    font-size: 2.5vw;
    right: 2vw;
    top: 1vw;
}
