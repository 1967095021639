@import url('https://fonts.googleapis.com/css2?family=Black+Ops+One&family=Quantico&display=swap');

@font-face {
	font-family: 'GoodTimesRg-Regular';
	src: url('./fonts/GoodTimesRg-Regular.ttf') format('truetype'),
			 url('./fonts/GoodTimesRg-Regular.woff2') format('woff2'),
			 url('./fonts/GoodTimesRg-Regular.woff') format('woff');
	font-weight: normal;
	font-style: normal;

}
h1,h2,h3,h4 {
	font-family: 'Quantico', sans-serif;
  margin: 1vw;
  color: #bba174;
  letter-spacing: 2vw;
  text-shadow: 0 0 2vw black;
  text-align: center;

}

html {
  height: 100%;
  background: radial-gradient(ellipse at bottom, #475046 0%, #444c43 100%);
}
input[type='text'] {
	outline: none;
}
h1 {
  font-family: 'Quantico', sans-serif;
  font-size: 12vw;
  margin: 1vw;
  color: #bba174;
  letter-spacing: 2vw;
  text-shadow: 0 0 2vw black;
  text-align: center;
}
div#root{
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	overflow: hidden;
	bottom: 0;
}
.overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  background-color: rgba(47, 45, 42, 0.7);
}

div.login {
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  z-index: 2;
  text-align: center;
  overflow: hidden;
}
div.login input[type='text'] {
  font-size: 3.5vw;
  width: 40vw;
  padding: 20px 100px;
  border-radius: 1vw;
  border: 0;
  /* text-shadow: 0 0 2vw black;
  box-shadow: 0 0 6vw black; */

  color: #ddca9c;
  background-color: #a4895c;
  margin: 0px auto;
  text-align: center;
  font-family: 'Black Ops One', cursive;
}
button.primary {
	font-size: 12vw;
  padding: 2vw 10vw;
  border-radius: 1vw;
  border: 0;
  text-shadow: 0 0 2vw black;
  box-shadow: 0 0 6vw black;
  color: #ddca9c;
  background-color: #736246;
  margin: 0px auto;
  text-align: center;
  font-family: 'Black Ops One', cursive;

}
button.confirm {
	font-size: 3.5vw;
  padding: 1vw 1vw;
  border-radius: 1vw;
  border: 0;
  text-shadow: 0 0 1vw black;
  box-shadow: 0 0 3vw black;
  color: #ddca9c;
  margin: 0px 1vw;
  text-align: center;
  /* font-family: 'Black Ops One', cursive; */

}

div.login button {
  font-size: 4vw;
  /* font-family: 'Black Ops One', cursive; */
}
div.login button:hover,
div.login button:hover:active {
  background-color: black;
}
div.App {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
}
.BgVideo {
  width: 100%;
  height: 100%;
  z-index: -2;
  -o-object-fit: cover;
  object-fit: cover;
  background: #232a34;
}
div.loading{
	background-color: rgb(29, 30, 34);
	width:100vw;
	text-align: center;
	height:100vh;

}
/*
Russian blue pattern:
#7be3f6
#4499a9
#276a76
#133b43
#03090a
*/
svg g {
	background:
radial-gradient(black 15%, transparent 16%) 0 0,
radial-gradient(black 15%, transparent 16%) 8px 8px,
radial-gradient(rgba(255,255,255,.1) 15%, transparent 20%) 0 1px,
radial-gradient(rgba(255,255,255,.1) 15%, transparent 20%) 8px 9px;
/* background-color:#282828; */
background-size:16px 16px;
  fill: darkolivegreen;
  /* fill-opacity: 0.2; */
}


svg g.active .hexagon {
  fill: Coral;
  opacity: 1;
}
svg g .hexagon {
  opacity: .4;
}
svg g.active-player .hexagon{
	opacity: 1;
}
svg g.player .hexagon {
  fill: white;
  opacity: 1;
}

svg g.moverange .hexagon {
  /* stroke: #736246; */
	opacity: 1;

}
svg g.active-player .hexagon polygon {
  /* animation: pulse-yellow 2s infinite; */
	/* transform: scale(1); */
	opacity: 1;

}

@keyframes pulse-yellow {
  0% {
    transform: scale(0.95);
    stroke-width: .4;
  }

  70% {
		/* stroke: white; */
    transform: scale(.9);
    stroke-width: .4;
		z-index: 20;
  }

  100% {
    transform: scale(0.95);
    stroke-width: .4;
  }
}


/* svg g.moverange .hexagon {
  fill-opacity: 0.9;
} */


svg g polygon {
  /* stroke: #444c43; */
  stroke-width: 0.4;
  transition: fill-opacity 0.5s;
}
svg g text {
  font-size: 0.15em;
	font-weight: bold;
  fill: white;
  fill-opacity: 0.7;
  transition: fill-opacity 0.5s;
}
svg path {
  fill: none;
  stroke: rgb(29, 30, 34);
  stroke-width: 0.1em;
  stroke-opacity: 0.9;
  stroke-linecap: round;
  stroke-linejoin: round;
}

/*https://codepen.io/alexzaworski/pen/LEWRxm*/
button {
	font-size: 4vw;
  padding: 1vw 1vw;
  border-radius: 2vw;
  border: .3vw rgb(26, 26, 26) solid;
	-webkit-touch-callout: none; /* iOS Safari */
	-webkit-user-select: none; /* Safari */
	-khtml-user-select: none; /* Konqueror HTML */
	-moz-user-select: none; /* Old versions of Firefox */
	-ms-user-select: none; /* Internet Explorer/Edge */
	user-select: none; /* Non-prefixed version, currently*/
	-webkit-tap-highlight-color: transparent;
	box-shadow:inset 0 -0.5em 0 -0.35em rgba(0,0,0,0.17);
	outline:none;
  /* text-shadow: 0 0 2vw black;
  box-shadow: 0 0 6vw black; */
  color: #fff;
  text-align: center;
  font-family: 'Black Ops One', cursive;
  cursor: pointer;
  margin-left: 5px;
  margin-bottom: 15px;

	text-shadow: 0 0 .5vw #000;
	/* text-shadow: -2px -2px 0 rgb(41, 41, 41), 2px -2px 0 rgb(41, 41, 41), -2px 2px 0 rgb(41, 41, 41), 2px 2px 0 rgb(41, 41, 41); */
	background-color: #736246;
	background-image: linear-gradient(180deg, #877467 0%, #736246 100%);
  box-sizing: border-box;
  text-decoration: none;
  display: inline-block;
  width: auto;
  border-radius: 5px;
  top: 0;
  transition: all 0.06s ease-out;
  position: relative;
}
article.section{
	font-family: 'GoodTimesRg-Regular';
	background: rgb(231, 239, 220);
  box-shadow: 0 0 40px -10px rgba(0, 0, 0, .4);
	margin:6vw 2vw;
	border-radius: 2vw;
	overflow: hidden;
}
article.section header{
	text-align: left;
	background-color: darkolivegreen;
	background-image: linear-gradient(180deg, rgb(157, 178, 121) 0%, darkolivegreen 100%);
	padding: 2vw;
	font-size:2.5vw;
}
article.section header span.left-title{
	color:#fff
}
article.section header span.right-title{
	float: right;
	color:#fff

}
/* button:visited {
  color: #5ea97d;
} */

/* button:hover {
  background: linear-gradient(to bottom, #baf1d1 0%, #b7e4ca 26%, #96c7ab 100%);
} */

button:active {
  top: 6px;
  /* text-shadow: 0 -2px 0 #7fbb98, 0 1px 1px #c2dece, 0 0 4px white; */
  color: white;
}
button:active:before {
  top: 0;
  /* box-shadow: 0 3px 3px rgba(0, 0, 0, 0.7), 0 3px 9px rgba(0, 0, 0, 0.2); */
}

button:before {
  display: inline-block;
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  z-index: -1;
  top: 6px;
  border-radius: 5px;
  height: 65px;
  /* background: linear-gradient(to top, #1e5033 0%, #378357 6px); */
  transition: all 0.078s ease-out;
  /* box-shadow: 0 1px 0 2px rgba(0, 0, 0, 0.3), 0 5px 2.4px rgba(0, 0, 0, 0.5), 0 10.8px 9px rgba(0, 0, 0, 0.2); */
}


/*Dice*/

.game {
  position: relative;
  width: auto;
  height: 100%;
}

.container {
  position: relative;
  display: inline-block;
  top: 200px;
}

.dice {
  position: relative;
  width: 100px;
  height: 100px;
  transform-style: preserve-3d;
  transition: transform 1s;
}

.dot {
  position: absolute;
  width: 20px;
  height: 20px;
  margin: -10px 5px 5px -10px;
  border-radius: 20px;
  background-color: #f25f5c;
  box-shadow: inset 2px 2px #d90429;
}

.dice-one {
  position: absolute;
  left: 150px;
}

.dice-two {
  position: absolute;
  left: -50px;

}

.side {
  position: absolute;
  background-color: #ffF;
  border-radius:5px;
  width: 100px;
  height: 100px;
  border: 1px solid #e5e5e5;
  text-align: center;
  line-height: 2em;
}

.side:nth-child(1) {
  transform: translateZ(3.1em); }

.side:nth-child(6) {
  transform: rotateY(90deg) translateZ(3.1em); }

.side:nth-child(3) {
  transform: rotateY(-90deg) translateZ(3.1em); }

.side:nth-child(4) {
  transform: rotateX(90deg) translateZ(3.1em); }

.side:nth-child(5) {
  transform: rotateX(-90deg) translateZ(3.1em); }

.side:nth-child(2) {
  transform: rotateY(-180deg) translateZ(3.1em); }

.show-1 {
  transform: rotateX(720deg) rotateZ(-720deg); }

.show-6 {
  transform: rotateX(-900deg) rotateZ(1080deg); }

.show-3 {
  transform: rotateY(-450deg) rotateZ(-1440deg); }

.show-4 {
  transform: rotateY(810deg) rotateZ(720deg); }

.show-5 {
  transform: rotateX(-810deg) rotateZ(-1080deg); }

.show-2 {
  transform: rotateX(450deg) rotateZ(-720deg); }

.two-1, .three-1, .four-1, .five-1, .six-1 {
  top: 20%;
  left: 20%;
}

.four-3, .five-3, .six-4 {
  top: 20%;
  left: 80%; }

.one-1, .three-2, .five-5 {
  top: 50%;
  left: 50%; }

.four-2, .five-2, .six-3 {
  top: 80%;
  left: 20%; }

.two-2, .three-3, .four-4, .five-4, .six-6 {
  top: 80%;
  left: 80%; }

.six-2 {
  top: 50%;
  left: 20%; }

.six-5 {
  top: 50%;
  left: 80%;
}

/* animation */
@keyframes slideInFromTop {
  0% {
    transform: translateY(-100%);
		opacity: 0;
  }
  100% {
    transform: translateY(0);
		opacity: 1;

  }
}

.playerMat{
	animation: .5s ease-out 0s 1 slideInFromTop;

}

/* animation */
@keyframes slideInFromBottom {
  0% {
    transform: translateY(100%);
		opacity: 0;
  }
  100% {
    transform: translateY(0);
		opacity: 1;

  }
}

.card{
	animation: .5s ease-out 0s 1 slideInFromBottom;

}

.pulse-container {
  width: 15vw;
  height: 100%;
  margin: 0 auto 0;
  -webkit-perspective: 1000;
  -webkit-backface-visibility: hidden;
  /* background: #fff; */
}

.pulse-button {
  position: relative;
  top: 50%;
  left: 50%;
  margin-left: -7.5vw;
  margin-top: -7.5vw;
  display: block;
  width: 15vw;
  /* height: 15vw; */
	font-family: 'Black Ops One', cursive;
  font-size: 2vw;
  font-weight: light;
  text-transform: uppercase;
  text-align: center;
  letter-spacing: -1px;
  color: white;
  border: none;
  border-radius: 2vw;
	padding:1vw;
  background: rgb(72, 115, 70);
  cursor: pointer;
  box-shadow: 0 0 0 0 rgba(72, 115, 70, 0.5);
  animation: pulse 1.5s infinite;
}


@keyframes pulse {
  0% {
    -moz-transform: scale(0.9);
    -ms-transform: scale(0.9);
    -webkit-transform: scale(0.9);
    transform: scale(0.9);
  }
  70% {
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    -webkit-transform: scale(1);
    transform: scale(1);
    box-shadow: 0 0 0 50px rgba(90, 153, 212, 0);
  }
  100% {
    -moz-transform: scale(0.9);
    -ms-transform: scale(0.9);
    -webkit-transform: scale(0.9);
    transform: scale(0.9);
    box-shadow: 0 0 0 0 rgba(90, 153, 212, 0);
  }
}


.scrolling-view {
	background-image: repeating-linear-gradient(-45deg, #000, #444c43 1px, transparent 1px, transparent 6px);
	background-size: 4px 4px;
}

.add-to-home-banner{
	z-index: 2;
}
